@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Work Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Work Sans", sans-serif;
}

@font-face {
  font-family: "Work Sans";
  font-weight: 400;
  src: url("./assets/font/WorkSans-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Work Sans";
  font-weight: 500;
  src: url("./assets/font/WorkSans-Medium.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Work Sans";
  font-weight: 600;
  src: url("./assets/font/WorkSans-SemiBold.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Work Sans";
  font-weight: 700;
  src: url("./assets/font/WorkSans-Bold.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Work Sans";
  font-weight: 600;
  font-style: italic;
  src: url("./assets/font/WorkSans-SemiBoldItalic.ttf") format("truetype");
  font-display: swap;
}


.shepherd-header {
  display: flex;
  background-color: white !important;
  padding: 0px !important;
  justify-content: space-between !important;
  align-items: start !important;
}

.shepherd-element {
  padding: 30px !important;
  border-radius: 16px !important;
  width: 400px !important;
  box-shadow: 0px 3px 7px #00000029 !important;
  max-width: 100% !important;
  background-color: white !important;
}

.shepherd-text {
  padding: 0 !important;
  color: #757686 !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}

.shepherd-title {
  color: #36374F !important;
  font-size: 25px !important;
  font-weight: 500 !important;
  margin-bottom: 10px !important;
}

.button-next-w {
  padding: 10px 20px !important;
  border-radius: 12px !important;
  background-color: #36374F !important;
  color: #FAFAFA !important;
  font-size: 18px !important;
}

.button-next-w:hover {
  background-color: #757686 !important;
  color: #FAFAFA !important;
}

.button-cancel {
  padding: 10px 20px !important;
  border-radius: 12px !important;
  background-color: white !important;
  color: #9596A1 !important;
  font-size: 18px !important;
}

.button-cancel:hover {
  background-color: #9596A1 !important;
  color: white !important;
}

.shepherd-footer {
  margin-top: 24px !important;
  justify-content: start !important;
  margin-left: 0 !important;
  padding: 0 !important;
}

.shepherd-cancel-icon {
  transition: transform 0.3s ease-in-out !important;
  color: #36374F !important;
  position: absolute !important;
  top: 13px !important;
  right: 30px !important;
  width: 18px !important;
  height: 18px !important;
}

.shepherd-cancel-icon:hover {
  /* transition-delay: 200ms !important;
  transform: rotate(-90deg) !important; */
  background-color: transparent !important;
}
.shepherd-cancel-icon::before {
  content: url('data:image/svg+xml,%3Csvg xmlns%3D%22http%3A//www.w3.org/2000/svg%22 viewBox%3D%220 0 24 24%22 fill%3D%22none%22 stroke%3D%22currentColor%22 stroke-width%3D%222%22 stroke-linecap%3D%22round%22 stroke-linejoin%3D%22round%22 class%3D%22feather feather-x%22%3E%3Cline x1%3D%2218%22 y1%3D%226%22 x2%3D%226%22 y2%3D%2218%22%3E%3C/line%3E%3Cline x1%3D%226%22 y1%3D%226%22 x2%3D%2218%22 y2%3D%2218%22%3E%3C/line%3E%3C/svg%3E');
  position: absolute;
  right: 0px !important;
  top: 0px !important;
  width: 18px;
  height: 18px;
}

.tooltipo {
  position: relative;
}

.tooltipo .tooltiptexto {
  visibility: hidden;
  width: auto;
  min-width: 100px;
  bottom: 90%;
  left: 0%;
  margin-left: 0px;
  /* Use half of the width (120/2 = 60), to center the tooltip */
  background-color: #55576a;
  color: #fafafa;
  font-size: 10.8px;
  line-height: 10.8px;
  text-align: left;
  border-radius: 5px;
  padding: 7px 10px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltipo:hover .tooltiptexto {
  transition-delay: 200ms;
  visibility: visible;
}

.tooltipo .tooltiptexto::after {
  content: " ";
  position: absolute;
  top: 100%;
  /* At the bottom of the tooltip */
  left: 20%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #55576a transparent transparent transparent;
}
